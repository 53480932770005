
<template>
    <vx-card>
      <!-- Content Row -->
      <div class="vx-row">

        <div class="vx-col w-full">
          <vx-card :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Nom Complet(*)" v-model="Nom_Complet" name="Nom complet" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full  mt-4" label="Nom Abregé (*)" v-model="Nom_Abrege" v-validate="'required'" name="Nom Abrege" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="N°IM" v-model="im" name="im" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Code postale" v-model="adresse" name="adresse" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Code client" v-model="codeClient" name="codeClient" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select autocomplete v-model="pays" class="w-full select-large mt-4" label="Pays">
                  <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item,index) in PaysData" class="w-full" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Télephone" v-model="telephone" name="Telephone" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Mobile" v-model="mobile"  name="Mobile" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Email" v-model="Email" name="Email" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Site internet" v-model="Site" name="Site" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" label="Compt Banquaire" v-model="ComptBanq" v-validate="'required|alpha_spaces'" name="compt Banquaire" />
              </div>
              <div class="vx-col md:w-1/2">
                <div class="mt-4 w-full">
                  <label class="text-sm">Délais Règlement</label>
                  <flat-pickr v-model="DelaiRegl" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DelaiRegl" />
                </div>
              </div>
              
            </div>
          </vx-card>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="updateAssurance">Modifier</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="annuler">Annuler</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
</template>
<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  name: 'assurance-edit',
  components: {
    vSelect,
    flatPickr
  },
  data () {
    
    return {
      Nom_Abrege :'',
      Nom_Complet :'',
      adresse :'',
      pays :'',
      telephone :'',
      mobile :'',
      fax :'',
      Email :'',
      Site :'',
      RegistreCom :'',
      ComptCont :'',
      ComptBanq :'',
      DelaiRegl : null,
      im: null,
      codeClient: null
    }
  },
  computed: {
    PaysData () {
      return this.$store.state.pays.pays
    }
  },
  methods: {
    annuler () {
      this.$router.push('/apps/assurances/assurances-list').catch(() => {})
    },
    updateAssurance () {
      const payload = {
        Nom_Abrege: this.Nom_Abrege,
        Nom_Complet: this.Nom_Complet,
        adresse: this.adresse,
        pays: this.pays,
        telephone: this.telephone,
        mobile: this.mobile,
        fax        :this.fax,
        Email : this.Email,
        Site  : this.Site,
        RegistreCom: this.RegistreCom,
        ComptCont  : this.ComptCont,
        ComptBanq: this.ComptBanq,
        DelaiRegl: this.DelaiRegl,
        im: this.im,
        codeClient: this.codeClient,
        id: this.$route.params.assuranceId
      }
      this.$vs.loading({
        type:'sound'
      })
      
      this.$store.dispatch('assurance/updateAssurance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          this.$vs.loading.close()
          this.$router.push('/apps/assurances/assurances-list').catch(() => {})
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getAssuranceById () {
      this.$store.dispatch('assurance/getAssuranceById', this.$route.params.assuranceId)
        .then((resp) => {
          this.Nom_Abrege = resp.data.Nom_Abrege
          this.Nom_Complet = resp.data.Nom_Complet
          this.adresse = resp.data.Adresse.adresse
          this.pays = resp.data.Adresse.pays
          this.telephone = resp.data.Adresse.telephone
          this.mobile = resp.data.Adresse.mobile
          this.fax = resp.data.Adresse.fax
          this.Email = resp.data.Adresse.Email
          this.Site = resp.data.Adresse.Site
          this.RegistreCom = resp.data.RegistreCom
          this.ComptCont = resp.data.ComptCont
          this.ComptBanq = resp.data.ComptBanq
          this.DelaiRegl = resp.data.DelaiRegl
          this.im = resp.data.im
          this.codeClient = resp.data.codeClient
        })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getAssuranceById()
    this.getPays()
  }
}
</script>